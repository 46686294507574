var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"color":"white"}},[(_vm.selected.length > 0)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","offset":"8"}},[_vm._v("With Selected:")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"hide-details":"","rounded":"","filled":"","items":_vm.outcomes,"dense":""},on:{"change":function($event){return _vm.massUpdateField()}},model:{value:(_vm.massOutcome),callback:function ($$v) {_vm.massOutcome=$$v},expression:"massOutcome"}})],1)],1):_vm._e(),(_vm.selected.length > 0)?_c('v-row',{attrs:{"align":"center"}},[(this.$account.isFullAdmin || this.$account.permissions.canEditAgentForMultipleUsers === true)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","offset":"8"}},[_vm._v("Edit agent for multiple user:")]):_vm._e(),(this.$account.isFullAdmin || this.$account.permissions.canEditAgentForMultipleUsers === true)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"item-text":"userName","item-value":"id","hide-details":"","rounded":"","filled":"","items":_vm.agentList,"dense":""},on:{"change":function($event){return _vm.massUpdateAgentField()}},model:{value:(_vm.massAgent),callback:function ($$v) {_vm.massAgent=$$v},expression:"massAgent"}})],1):_vm._e()],1):_vm._e(),_c('v-data-table',{attrs:{"single-expand":"","show-select":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"hide-default-footer":"","items-per-page":_vm.resultsPerPage,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.clickColumn(item)}}},[_vm._v(" "+_vm._s(item.id.slice(-5))+" ")])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/users/" + (item.id))}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}},{key:"item.registerDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.registerDate))+" ")]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("countryCodeToFullName")(item.country)))]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.balance)))]}},(this.$account.isFullAdmin || this.$account.permissions.canEditOutcome === true)?{key:"item.outcome",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"rounded-xl",attrs:{"rounded":"","filled":"","hide-details":"","items":_vm.outcomes,"dense":""},on:{"change":function($event){return _vm.updateUserField(item, 'outcome')}},model:{value:(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)].outcome),callback:function ($$v) {_vm.$set(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)], "outcome", $$v)},expression:"users[users.map(i => i.id).indexOf(item.id)].outcome"}})]}}:null,(this.$account.isFullAdmin || this.$account.permissions.canEditAgent === true)?{key:"item.agentId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"rounded-xl",attrs:{"rounded":"","filled":"","hide-details":"","item-text":"userName","item-value":"id","items":_vm.agentList,"dense":""},on:{"change":function($event){return _vm.updateUserField(item, 'agentId')}},model:{value:(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)].agentId),callback:function ($$v) {_vm.$set(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)], "agentId", $$v)},expression:"users[users.map(i => i.id).indexOf(item.id)].agentId"}})]}}:{key:"item.agentId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"rounded-xl",attrs:{"rounded":"","filled":"","hide-details":"","item-text":"userName","item-value":"id","disabled":"","items":_vm.agentList,"dense":""},on:{"change":function($event){return _vm.updateField(item, 'agentId')}},model:{value:(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)].agentId),callback:function ($$v) {_vm.$set(_vm.users[_vm.users.map(function (i) { return i.id; }).indexOf(item.id)], "agentId", $$v)},expression:"users[users.map(i => i.id).indexOf(item.id)].agentId"}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"additional-row",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"additional-row-head-wrapper"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Last Login")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Status")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Total Deposits")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Total Withdrawals")])])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.lastLoginDate))]),_c('td',[_vm._v(_vm._s(item.accountType))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalDeposits)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalWithdrawals)))])])])])])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination_btn pagination-btn--previous",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{staticClass:"pagination_btn-icon mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v("Previous ")],1),_c('p',{staticClass:"pagination_total-page"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages)+" ("+_vm._s(_vm.totalUsers)+" results)")]),_c('button',{staticClass:"pagination_btn pagination-btn--next",attrs:{"disabled":_vm.currentPage === _vm.totalPages},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next"),_c('v-icon',{staticClass:"pagination_btn-icon ml-2"},[_vm._v("mdi-chevron-right")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }